import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import MailIcon from "@material-ui/icons/Mail"
import MenuIcon from "@material-ui/icons/Menu"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ImgLauraPrada from "../components/imgComponents/imgLauraPrada"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUserMd,
  faGraduationCap,
  faAddressCard,
  faFileSignature,
  faHospital,
} from "@fortawesome/free-solid-svg-icons"
import { Button, Container, Grid } from "@material-ui/core"
import TranslateIcon from "@material-ui/icons/Translate"
import { Link } from "gatsby"
import Avatar from "@material-ui/core/Avatar"

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: "lightpink",
    // backgroundImage: "linear-gradient(#eff4ff, #ffe0e0)",
    backgroundImage: "radial-gradient(circle, #5c0067 0%, #00d4ff 100%)",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}))

function ResponsiveDrawer(props) {
  const { t, i18n } = useTranslation()

  let languageDetector = i18next.languages[0].substr(0, 2)
  if (languageDetector === "es") {
    languageDetector = "en"
  } else {
    languageDetector = "es"
  }
  const [language, setLanguage] = useState(languageDetector)
  const onChangeLanguage = () => {
    i18n.changeLanguage(language)
    if (language === "es") {
      setLanguage("en")
    } else {
      setLanguage("es")
    }
  }

  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <Box
        align="center"
        style={{
          marginLeft: "24px",
          marginRight: "24px",
          marginTop: "24px",
          background:
            "linear-gradient(90deg,rgba(255,0,0,0.38) 0%, rgba(0,0,0,0.47) 80%)",
        }}
      >
        <ImgLauraPrada />
      </Box>
      <br />
      <Box align="center" style={{ color: "#fff", fontWeight: "bold" }}>
        <span>Dr. Laura Prada</span>
      </Box>
      <Box align="center" style={{ color: "#f1faee" }}>
        <small>{t("maindrawer.generalpractitioner")}</small>
        <p style={{ margin: "0", padding: "0", lineHeight: "0.9" }}>
          <small>{t("maindrawer.residencyt1")}</small>
          <br />
          <small>{t("maindrawer.residencyt2")}</small>
          <br />
          <small>{t("maindrawer.residencyt3")}</small>
        </p>
      </Box>
      <br />
      <Divider style={{ backgroundColor: "#2D82B7" }} />
      <List style={{ color: "#B8F3FF" }}>
        <ListItem button component={Link} to="/">
          <ListItemIcon style={{ color: "#B8F3FF" }}>
            <FontAwesomeIcon icon={faUserMd} size="lg" />
          </ListItemIcon>
          <ListItemText primary={t("maindrawer.professionalprofile")} />
        </ListItem>
        <ListItem button component={Link} to="/experience">
          <ListItemIcon style={{ color: "#B8F3FF" }}>
            <FontAwesomeIcon icon={faHospital} size="lg" />
          </ListItemIcon>
          <ListItemText primary={t("maindrawer.experience")} />
        </ListItem>
        <ListItem button component={Link} to="/studies">
          <ListItemIcon style={{ color: "#B8F3FF" }}>
            <FontAwesomeIcon icon={faGraduationCap} size="lg" />
          </ListItemIcon>
          <ListItemText primary={t("maindrawer.studies")} />
        </ListItem>
        <ListItem button component={Link} to="/associations">
          <ListItemIcon style={{ color: "#B8F3FF" }}>
            <FontAwesomeIcon icon={faAddressCard} size="lg" />
          </ListItemIcon>
          <ListItemText primary={t("maindrawer.associations")} />
        </ListItem>
        <ListItem button component={Link} to="/references">
          <ListItemIcon style={{ color: "#B8F3FF" }}>
            <FontAwesomeIcon icon={faFileSignature} size="lg" />
          </ListItemIcon>
          <ListItemText primary={t("maindrawer.references")} />
        </ListItem>
      </List>
      <Divider style={{ backgroundColor: "#2D82B7" }} />
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          backgroundImage: "radial-gradient(circle, #5c0067 0%, #00d4ff 100%)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap className={classes.title}>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              Dr. Laura Prada
            </Link>
          </Typography>

          <Button
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={<TranslateIcon />}
            onClick={onChangeLanguage}
          >
            {t("home.button.language")}
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
      </main>
    </div>
  )
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
}

export default ResponsiveDrawer
